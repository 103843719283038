<template>
    <div
        class="feedback_modal"
    >
        <div class="feedback_modal-content">
            <div class="feedback_modal__header">
                <div class="feedback_modal__header-data">
                    <avatar
                        :avatar-url="order.writer.avatar_url"
                        :nickname="order.writer.name"
                        :user="'writer'"
                    />
                    <div class="feedback_modal__header-text">
                        <p>
                            <span class="feedback_modal__header-name">{{ order.writer.name }}</span>
                            (ID: {{ order.writer.id }})
                        </p>
                        <p class="feedback_modal__header-text-order">
                            <img
                                src="@/assets/img/svg/barcode.svg"
                                alt="barcode"
                                width="20px"
                                height="20px"
                                class="card-icon"
                            >
                            {{ order.orderid }}
                        </p>
                        <p>
                            &laquo;<em>{{ order.project_information.title }}</em>&raquo;
                        </p>
                    </div>
                </div>

                <span
                    class="feedback_modal__close"
                    @click="close()"
                >
                    <CloseIcon />
                </span>
            </div>

            <template>
                <div
                    v-for="(item, index) in tabs"
                    v-show="activeTab === index"
                    :key="index"
                    class="feedback_modal__body"
                >
                    <div>
                        <p class="feedback_modal__body-title">
                            <b>
                                {{ activeTab+1 }} of {{ tabs.length }}
                            </b>
                            {{ tabs[activeTab].title }}
                        </p>
                        <div
                            v-if="tabs[activeTab].name !== 'message'"
                            class="feedback_modal__body-rating"
                        >
                            <p class="feedback_modal__body-item__title">
                                {{ tabs[activeTab].name }}
                            </p>
                            <star-rating
                                v-model="form[tabs[activeTab].name]"
                                :star-size="30"
                                :padding="5"
                                :show-rating="false"
                                :active-color="themecolor"
                            />
                        </div>
                        <div
                            v-show="(form[tabs[activeTab].name] && form[tabs[activeTab].name] < 4) || tabs[activeTab].name === 'message'"
                            class="feedback_modal__body-rating_text"
                        >
                            <form-textarea
                                v-model="$v.form[tabs[activeTab].details_name].$model"
                                :legend="tabs[activeTab].question"
                                type="text"
                                :name="tabs[activeTab].question"
                                :field.sync="form[tabs[activeTab].details_name]"
                                :errors="validationMessage($v.form[tabs[activeTab].details_name])"
                                @input.native="$v.form[tabs[activeTab].details_name].$touch();"
                                @blur.native="$v.form[tabs[activeTab].details_name].$touch();"
                            />
                        </div>
                    </div>
                </div>
            </template>

            <div class="feedback_modal__progress">
                <span
                    :style="`width: ${progress}%`"
                />
            </div>

            <!-- footer buttons -->
            <footer class="feedback_modal__btns">
                <button
                    :disabled="activeTab === 0"
                    depressed
                    block
                    class="btn-base btn-border"
                    @click.prevent="previousStep()"
                >
                    Back
                </button>

                <button
                    v-if="activeTab < 5"
                    :disabled="activeTab === 5 || !isCurrentStepValid"
                    text
                    block
                    class="btn-base btn-main feedback_modal__send"
                    @click.prevent="nextStep(tabs[activeTab].name)"
                >
                    Next
                </button>

                <!-- final button -->
                <button
                    v-else
                    :disabled="!isCurrentStepValid"
                    class="btn-base btn-main feedback_modal__send"
                    @click.prevent="validate(false)"
                >
                    Send
                </button>
                <!-- final button -->
            </footer>
            <!-- footer buttons -->
        </div>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import CloseIcon from 'mdi-vue/Close.vue';
import StarRating from 'vue-star-rating'
import { eventBus } from '@/helpers/event-bus'

import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/feedback/Rules'
import { formMessages } from '@/validation/feedback/Message'

import Avatar from '@/components/common/Avatar.vue'

const Env = require('@/helpers/environment/index.js');

const EnvSettings = new Env(process.env.VUE_APP_DOMAIN);

export default {
    validations: {
        ...validationRules
    },
    components: {
        CloseIcon,
        Avatar,
        StarRating
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    props: {
        order: {
            type: Object
        }
    },
    data: () => ({
        dialog: false,
        activeTab: 0,
        isEdit: false,
        testimonialID: null,
        form: {
            delivery: null,
            delivery_details: '',
            content: null,
            content_details: '',
            communication: null,
            communication_details: '',
            website: null,
            website_details: '',
            support: null,
            support_details: '',
            message: ''
        },
        themecolor: EnvSettings.styleSetting.color
    }),
    computed: {
        progress() {
            const calculatedProgress = ((this.activeTab) / this.tabs.length) * 100
            return calculatedProgress
        },
        isCurrentStepValid() {
            switch (this.activeTab) {
            case 0:
                return !this.$v.form.delivery.$invalid && !this.$v.form.delivery_details.$invalid
            case 1:
                return !this.$v.form.content.$invalid && !this.$v.form.content_details.$invalid
            case 2:
                return !this.$v.form.communication.$invalid && !this.$v.form.communication_details.$invalid
            case 3:
                return !this.$v.form.website.$invalid && !this.$v.form.website_details.$invalid
            case 4:
                return !this.$v.form.support.$invalid && !this.$v.form.support_details.$invalid
            case 5:
                return !this.$v.form.message.$invalid
            default:
                return false
            }
        },
        tabs() {
            return [
                {
                    active: false,
                    name: 'delivery',
                    details_name: 'delivery_details',
                    title: 'Was the project delivered on time?',
                    question: 'Describe your problem',
                    details_min: 20,
                    details_max: 200
                },
                {
                    active: false,
                    name: 'content',
                    details_name: 'content_details',
                    title: 'Was the content of good quality?',
                    question: 'Describe your problem'
                },
                {
                    active: false,
                    name: 'communication',
                    details_name: 'communication_details',
                    title: 'Did the writer communicate well?',
                    question: 'Describe your problem'
                },
                {
                    active: false,
                    name: 'website',
                    details_name: 'website_details',
                    title: 'How would you rate our website?',
                    question: 'Describe your problem'
                },
                {
                    active: false,
                    name: 'support',
                    details_name: 'support_details',
                    title: 'Was our support team helpful?',
                    question: 'Describe your problem'
                },
                {
                    active: false,
                    name: 'message',
                    details_name: 'message',
                    title: 'Please tell us about your overall experience',
                    question: 'Message'
                }
            ]
        }
    },
    watch: {
        'form.delivery': function (val) {
            if (+val >= 4) {
                this.form.delivery_details = ''
            }
        },
        'form.content': function (val) {
            if (+val >= 4) {
                this.form.content_details = ''
            }
        },
        'form.communication': function (val) {
            if (+val >= 4) {
                this.form.communication_details = ''
            }
        },
        'form.website': function (val) {
            if (+val >= 4) {
                this.form.website_details = ''
            }
        },
        'form.support': function (val) {
            if (+val >= 4) {
                this.form.support_details = ''
            }
        }
    },
    created() {
        this.setDefaultState()
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        setDefaultState() {
            const [testimonial] = this.order.testimonials
            if (testimonial) {
                this.form.delivery = Number(testimonial.delivery) || 1
                this.form.delivery_details = testimonial.delivery_details
                this.form.content = Number(testimonial.content) || 1
                this.form.content_details = testimonial.content_details
                this.form.communication = Number(testimonial.communication) || 1
                this.form.communication_details = testimonial.communication_details
                this.form.website = Number(testimonial.website) || 1
                this.form.website_details = testimonial.website_details
                this.form.support = Number(testimonial.support) || 1
                this.form.support_details = testimonial.support_details
                this.form.message = testimonial.details
                this.testimonialID = testimonial.testimonialid
                this.isEdit = true
            }
        },
        nextStep(name) {
            this.$v.$reset()
            if ((name === 'delivery') && !this.$v.form.delivery.$invalid && !this.$v.form.delivery_details.$invalid) {
                this.activeTab += 1
            } else if (name === 'content' && !this.$v.form.content.$invalid && !this.$v.form.content_details.$invalid) {
                this.activeTab += 1
            } else if (name === 'communication' && !this.$v.form.communication.$invalid && !this.$v.form.communication_details.$invalid) {
                this.activeTab += 1
            } else if (name === 'website' && !this.$v.form.website.$invalid && !this.$v.form.website_details.$invalid) {
                this.activeTab += 1
            } else if (name === 'support' && !this.$v.form.support.$invalid && !this.$v.form.support_details.$invalid) {
                this.activeTab += 1
            } else {
                this.$v.$touch()
                eventBus.$emit('showSnackBar', 'Please complete the form', 'error');
            }
        },
        previousStep() {
            if (this.activeTab > 0) {
                this.activeTab -= 1
            }
        },
        async submit() {
            this.loading = true
            const data = {
                ...this.form,
                orderid: this.order.orderid
            }
            Object.entries(data).forEach(([key, value]) => {
                if (value === '') {
                    delete data[key]
                }
            });

            this.$emit('confirmModal', { ...data, id: this.testimonialID }, this.isEdit)
        },
        close() {
            this.$emit('closeModal')
        }
    }
}
</script>

<style lang="scss" scoped>
    .feedback_modal{
        position: fixed;
        overflow: hidden;
        background-color: rgba(11, 19, 31, .45);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        overscroll-behavior: contain;
        z-index: 900;
        display: flex;
        justify-content: center;
        align-items: center;
        &-content{
            max-width: 500px;
            width: 100%;
            background-color: #fff;
            padding: 20px;
            position: relative;
        }
        &__close{
            position: absolute;
            right: -3px;
            top: 0px;
            cursor: pointer;
        }
        &__header{
            position: relative;
            &-data{
                display: flex;
                .avatar-wrapper{
                    margin-right: 20px;
                }
            }
            &-text{
                font-size: 16px;
                line-height: 22px;
                color: $font-color-main;
                &-order{
                    >img{
                        display: none;
                    }
                    &::before{
                        content: "Order ID:";
                        margin-right: 10px;
                        display: block;
                    }
                }
                p {
                    display: flex;
                    align-items: center;
                    margin: 5px 0;
                    & > span{
                        font-weight: 700;
                        color: $font-color-main;
                        margin-right: 10px;
                    }
                    img{
                        margin-right: 10px;
                    }
                }
            }
            &-name {
                text-transform: capitalize;
            }
        }
        &__body{
            margin-top: 20px;
            padding-top: 20px;
            position: relative;
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 1px;
                background: #EAEFF2;
            }
            &-title{
                font-size: 16px;
                color: $font-color-main;
                font-weight: 700;
                margin-bottom: 20px;
            }
            &-rating{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
            }
            &-rating_text{
                margin-top: 30px;
                margin-bottom: 20px;
            }
        }
        &__btns{
            .btn-base{
                min-width: 110px;
                &:disabled{
                    background: rgba(0,0,0,.12)!important;
                    border: none;
                    color: rgba(0,0,0,.26)!important;
                    box-shadow: none !important;
                    cursor: default;
                    pointer-events: none;
                }
                &:first-child{
                    margin-right: 20px;
                }
            }
        }
        &__progress{
            position: absolute;
            height: 4px;
            width: 100%;
            top: 0;
            left: 0;
            background: #EAEFF2;
            span{
                background: $border-gradient;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
            }
        }
    }

</style>
