<template>
    <div class="card_body__detail card_body__detail-inf">
        <div class="card_body__detail-title">
            {{ titleOfAdditionalInfo }}
        </div>
        <div
            class="card_body__detail-counter"
            style="font-weight: 700"
            :class="{'card_body__detail-counter--not-selected' : value === 0 }"
        >
            <component :is="icon" />
            {{ value > 0 ? value : 0 }}
        </div>
    </div>
</template>

<script>

import FileOutlineIcon from 'mdi-vue/FileOutline.vue'
import ImageOutlineIcon from 'mdi-vue/ImageOutline.vue'
import ChartBoxOutlineIcon from 'mdi-vue/ChartBoxOutline.vue'
import AlertOutlineIcon from 'mdi-vue/AlertOutline.vue'

export default {
    name: 'OrderCardBodyAdditionalInfo',
    components: {
        FileOutlineIcon,
        ImageOutlineIcon,
        ChartBoxOutlineIcon,
        AlertOutlineIcon
    },
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: Number,
            default: null
        },
        icon: {
            type: String,
            required: true
        }
    },
    computed: {
        titleOfAdditionalInfo() {
            if (this.value) {
                return this.value === 1 ? this.title : `${this.title}s`
            }
            return this.title
        }
    }
}

</script>
