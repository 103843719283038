import {
    required,
    minLength
} from 'vuelidate/lib/validators';

export const validationRules = {
    message: {
        required,
        minLength: minLength(20)
    }
}
