<template>
    <div
        class="confirm-modal"
        @click.self="close()"
    >
        <div class="confirm-modal__dialog">
            <header class="confirm-modal__header">
                <h2>Deadline</h2>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                >
                    <CloseIcon />
                </span>
            </header>
            <section class="confirm-modal__content">
                <div class="action-block">
                    <div>
                        <form-date-picker
                            v-model="$v.duedate.$model"
                            label="Due date"
                            tabindex="0"
                            :min_hours="3"
                            :field.sync="duedate"
                            :errors="validationMessage($v.duedate)"
                            @change.native="$v.duedate.$touch()"
                        />
                    </div>
                    <div class="confirm-modal__alert">
                        <div class="confirm-modal__alert-icon">
                            <AlertCircle />
                        </div>
                        The new deadline must be ahead of the current deadline ({{ getDueHours(order.due_at) | moment_short_text }})
                    </div>
                </div>
            </section>
            <footer>
                <div class="confirm-modal__one-button-block">
                    <button
                        class="btn-base btn-main"
                        @click.prevent="validate(false)"
                    >
                        Ok
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import CloseIcon from 'mdi-vue/Close.vue';
import AlertCircle from 'mdi-vue/AlertCircle.vue';
import filtersMixin from '@/mixins/filtersMixin.js'

// validation
import formValidationMixin from '@/mixins/formValidationMixin.js'
import { validationRules } from '@/validation/ExtendDeadline/Rules.js'
import { formMessages } from '@/validation/ExtendDeadline/Messages.js'

export default {
    name: 'DeadlineModal',
    components: {
        CloseIcon,
        AlertCircle
    },
    mixins: [validationMixin, formValidationMixin, filtersMixin],
    validations: {
        ...validationRules
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        dialog: false,
        duedate: null
    }),
    mounted() {
        this.duedate = moment(this.due).add(3, 'hours').toISOString()
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        getDueHours(duedate) {
            const due = moment(duedate)
            return due.add(3, 'hours');
        },
        async submit() {
            const data = {
                id: this.order.orderid,
                datetime: this.duedate
            }
            this.$emit('confirmModal', data)
        },
        close() {
            this.$emit('closeModal')
        }
    }
}
</script>

<style lang="scss">
    .confirm-modal__alert{
        display: flex;
        color: #fff;
        background-color: #2196f3;
        border-color: #2196f3;
        padding: 16px;
        margin-top: 20px;
        border-radius: $input-border-radius;
        &-icon{
            margin-right: 20px;
        }
    }
</style>
