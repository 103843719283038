<template>
    <card>
        <template #header="{ isCardOpen, slideCard }">
            <order-card-header-new
                :slot="'header'"
                :mode="mode"
                :order="order"
                :index="index"
                :is-card-open="isCardOpen"
                :slide-card="slideCard"
                :unread-messages-count="unreadMessagesCount"
                :unread-ticket-messages-count="unreadTicketMessagesCount"
                :unread-files-main-count="unreadFilesMainCount"
                :unread-files-add-count="unreadFilesAddCount"
                :additional-class="mode !== 'list' ? 'card_title--detail' : ''"
                data-is-link="true"
                @show:modal="showModal"
            />
        </template>
        <order-card-body
            :slot="'content'"
            :order="order"
            @show:modal="showModal"
        />

        <!--        <template :slot="'footer'">-->
        <!--            <footer-actions-->
        <!--                :data="order"-->
        <!--                @show:modal="showModal"-->
        <!--            />-->
        <!--        </template>-->
    </card>
</template>

<script>
import Card from '@/components/account/Cards/Card.vue'
import OrderCardHeaderNew from '@/components/account/Cards/OrderCard/OrderCardHeaderNew.vue'
import OrderCardBody from '@/components/account/Cards/OrderCard/OrderCardBody.vue'
// import FooterActions from '@/components/account/Cards/OrderCard/FooterActions.vue'

export default {
    name: 'OrderCard',
    components: {
        Card,
        OrderCardBody,
        OrderCardHeaderNew
        // FooterActions
    },
    props: {
        order: {
            type: Object,
            required: true
        },
        index: {
            type: Number
        },
        page: {
            type: Number
        },
        mode: {
            type: String,
            default: 'list'
        },
        unreadMessagesCount: {
            type: Number,
            required: false
        },
        unreadTicketMessagesCount: {
            type: Number,
            required: false
        },
        unreadFilesMainCount: {
            type: Number,
            required: false
        },
        unreadFilesAddCount: {
            type: Number,
            required: false
        }
    },
    methods: {
        // Show popaps
        showModal(payload) {
            this.$emit('show:modal', payload)
        }
    }
}
</script>
