<template>
    <div
        class="confirm-modal"
        @click.self="close()"
    >
        <div class="confirm-modal__dialog">
            <header class="confirm-modal__header">
                <h2 class="modal__header-title">
                    Order extra pages
                </h2>
                <p class="modal__header-description">
                    {{ order.orderid }}
                </p>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                >
                    <CloseIcon />
                </span>
            </header>
            <section class="confirm-modal__content">
                <div class="action-block">
                    <div class="confirm-modal__alert">
                        <div class="confirm-modal__alert-icon">
                            <AlertCircle />
                        </div>
                        You will be taken to the order form which will be populated with the order details. You still need to choose the deadline.
                    </div>
                </div>
            </section>
            <footer>
                <div class="confirm-modal__one-button-block">
                    <a
                        class="btn-base btn-main"
                        @click.prevent="goToOrderForm"
                    >
                        Proceed
                    </a>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import CloseIcon from 'mdi-vue/Close.vue';
import AlertCircle from 'mdi-vue/AlertCircle.vue';

export default {
    components: {
        CloseIcon,
        AlertCircle
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    methods: {
        close() {
            this.$emit('closeModal')
        },
        goToOrderForm() {
            this.close()
            this.$router.push({
                name: 'order_new',
                query: {
                    linked_to_order: this.order.orderid
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .confirm-modal__alert{
        display: flex;
        color: #fff;
        background-color: #2196f3;
        border-color: #2196f3;
        padding: 16px;
        margin-top: 20px;
        border-radius: $input-border-radius;
        &-icon{
            margin-right: 20px;
        }
    }
</style>
