<template>
    <div class="order-action__wrapper">
        <span
            :class="{'order-action__chevron-wrapper' : !isOrderPopupShow}"
            @click="showActionPopup"
        >
            <span :class="isOrderPopupShow ? 'order-actions__chevron-close' : 'order-actions__chevron-open'" />
        </span>
        <div
            v-if="isOrderPopupShow"
            v-click-outside="outsideCloseMenu"
            class="order-action__window"
        >
            <!-- delete -->
            <span
                v-if="allowDelete"
                class="order-action__item order-action__item-delete"
                @click.prevent="deleteOrder"
            >
                <DeleteOutline />
                Delete
            </span>
            <!-- delete -->

            <!-- feedback -->
            <span
                v-if="allowFeedback"
                class="order-action__item"
                @click.prevent="feedback"
            >
                <star
                    style="margin-right: 10px"
                />
                Rate Writer {{ data.sw_id }}
            </span>
            <!-- feedback -->

            <!-- deadline -->
            <span
                v-if="allowDeadline"
                class="order-action__item order-action__item-icon-deadline"
                @click.prevent="deadline"
            >
                <Pencil
                    style="margin-right: 10px;"
                />
                Extend deadline
            </span>
            <!-- deadline -->

            <!-- extra pages -->
            <span
                v-if="allowExtraPages"
                class="order-action__item order-action__item-icon-extra-pages"
                @click.stop="extraPages"
            >
                <Writing
                    style="margin-right: 10px;"
                />
                Order Extra Pages
            </span>
            <!-- extra pages -->

            <!-- revision -->
            <span
                v-if="allowRevision"
                class="order-action__item"
                @click.prevent="revision"
            >
                <Loupe
                    style="margin-right: 10px;"
                />
                Revision
            </span>
            <!-- revision -->
        </div>
    </div>
</template>

<script>

import Star from '@/components/icons/Star.vue';
import Loupe from '@/components/icons/Loupe.vue';
import Writing from '@/components/icons/Writing.vue';
import Pencil from '@/components/icons/Pencil.vue';
import DeleteOutline from 'mdi-vue/DeleteOutline.vue'

export default {
    components: {
        Star,
        Loupe,
        Writing,
        Pencil,
        DeleteOutline
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isOrderPopupShow: false
        }
    },
    computed: {
        moreDetails() {
            return this.$route.name === 'orders'
        },
        allowExtraPages() {
            const array = [
                'PAID',
                'PENDING',
                'COMPLETE',
                'REVISION'
            ]
            return array.includes(this.data.status)
        },
        allowFeedback() {
            const array = [
                'COMPLETE',
                'REFUND',
                'CREDIT'
            ]
            if (!this.data.writer || this.data.testimonials.length) return false

            if (array.indexOf(this.data.status) === -1) return false

            return true
        },
        allowDelete() {
            const array = [
                'UNPAID',
                'INQUIRY'
            ]
            return array.includes(this.data.status)
        },
        allowDeadline() {
            const array = [
                'PAID',
                'REVISION',
                'PENDING'
            ]
            return array.includes(this.data.status)
        },
        allowRevision() {
            const array = [
                'COMPLETE'
            ]
            return array.includes(this.data.status)
        }
    },
    methods: {
        // Show popaps
        deleteOrder() {
            this.$emit('show:modal', {
                modal: 'confirm-modal',
                orderid: this.data.orderid,
                action: 'delete',
                data: {
                    title: 'Delete',
                    description: `Are you sure you want to delete order ${this.data.orderid}`
                }
            })
        },
        feedback() {
            this.$emit('show:modal', {
                orderid: this.data.orderid,
                action: 'feedback',
                modal: 'feedback-modal'
            })
        },
        revision() {
            this.$emit('show:modal', {
                orderid: this.data.orderid,
                action: 'revision',
                modal: 'revision-modal'
            })
        },
        deadline() {
            this.$emit('show:modal', {
                orderid: this.data.orderid,
                action: 'deadline',
                modal: 'deadline-modal'
            })
        },
        extraPages() {
            this.$emit('show:modal', {
                orderid: this.data.orderid,
                action: 'extra-pages',
                modal: 'extra-pages-modal'
            })
        },
        showActionPopup() {
            this.isOrderPopupShow = !this.isOrderPopupShow
        },
        outsideCloseMenu() {
            this.isOrderPopupShow = false
        }
    }
}
</script>

<style lang="scss">

.order-action{
    &__wrapper {
        position: relative;
        cursor: pointer;
    }
    &__window{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 200px;
        background: #fff;
        color: #435A7D;
        padding: 10px 15px;
        font-size: 14px;
        font-weight: 700;
        right: 0;
        top: 45px;
        border-radius: 4px;
        z-index: 1;
        box-shadow: 0px 8px 24px -4px rgba(16, 24, 40, 0.08), 0px 4px 8px -4px rgba(16, 24, 40, 0.03);
        @media all and (max-width: 1350px) {
            right: 0;
        }
    }
    &__item {
        padding: 10px 0;
        white-space: nowrap;
        display: flex;
        align-items: flex-end;
        align-items: center;
    }
    &__item svg path {
        stroke: #435A7D;
        fill: #435A7D;
    }
    &__item-delete span svg {
        fill: #435A7D;
        width: 24px;
        height: 24px;
        margin-right: 5px;
    }
    &__item-icon-extra-pages {
        svg {
            width: 16px;
            height: 16px;
        }
    }
    &__item-icon-deadline {
        svg {
            width: 16px;
            height: 16px;
        }
    }
}

.order-actions__chevron-open {
    &:after {
        content: "";
        padding: 18px;
        border-radius: 50%;
        display: inline-flex;
        margin: 0 auto;
        transform: rotateZ(90deg);
        background: url("~@/assets/img/svg/ellipsis.svg") no-repeat center;
    }
}

.order-actions__chevron-close {
    &:after {
        content: "";
        border-radius: 50%;
        display: inline-flex;
        padding: 18px;
        margin: 0 auto;
        background: url("~@/assets/img/svg/close.svg") no-repeat center;
        background-color: rgba(42, 53, 71, 0.2);
    }
}

.order-action__chevron-wrapper{
    border-radius: 50%;
    display: inline-flex;
    &:hover{
        background-color: rgba(42, 53, 71, 0.2);
    }
}

</style>
