<template>
    <div style="display: flex; flex-direction: column">
        <div class="order-card-header">
            <div
                class="order-card-header__left-side"
                :class="{'order-card-header__left-side-details' : $route.name.includes('order_')}"
            >
                <div class="order-card-header__left-side-date-wrapper">
                    <span
                        v-if="mode === 'list'"
                        class="order-card-header__left-side-index"
                    >
                        {{ index + 1 }}
                    </span>
                    <!-- start: dates -->
                    <span class="order-card-header__left-side-date">
                        <span style="font-weight: 600">
                            {{ order.created_at | moment_from }} ago
                        </span>
                        <span
                            v-if="order.completed_at && order.status === 'COMPLETE'"
                            class="date--success"
                        >
                            Done: <span style="font-weight: 600; margin-left: 3px;">{{ order.completed_at | moment_from }} ago</span>
                        </span>
                        <span
                            v-else
                            class="date"
                            :class="{ 'date--error' : dueInAgo }"
                        >
                            <p v-if="dueInAgo">
                                Overdue: <span style="font-weight: 600; margin-left: 3px;">{{ order.due_at | moment_from }} ago</span>
                            </p>
                            <p v-else>
                                Due: <span style="font-weight: 600; margin-left: 3px;"> in {{ order.due_at | moment_from }}</span>
                            </p>
                        </span>
                    </span>
                </div>
                <!-- end: dates -->
                <!-- start: status -->
                <span
                    class="order-card-header__left-side-status card_title__status"
                    :class="getStatusClassName"
                >
                    {{ $t(`components.order_filter.statuses.${order.status}.statusReadable`) }}
                </span>
                <!-- end: status -->
            </div>
            <div class="order-card-header__right-side">
                <div class="order-card-header__top">
                    <!-- start: title -->
                    <router-link
                        :is="mode === 'list' ? 'router-link' : 'span'"
                        v-if="order.orderid"
                        :to="mode === 'list' && {
                            name: 'order_details',
                            params: {
                                id: order.orderid
                            }
                        }"
                        class="order-card-header__top-title"
                    >
                        <div class="order-card-header__top-topic">
                            <span class="order-card-header__top-subtopic">
                                <span style="font-weight: 700">{{ projectType }}</span>
                            </span>
                            <span class="order-card-header__top-subtopic order-card-header__top-subtopic--title">
                                «{{ titleFragment }}»
                                <span
                                    class="custom-tooltip"
                                    :class="{'order-card-header__top-custom-tooltip-none' : !isShowToolTip}"
                                >
                                    <p>!</p>
                                    <span>
                                        {{ order.project_information.title }}
                                    </span>
                                </span>
                            </span>
                        </div>
                    </router-link>
                    <!-- end: title -->
                    <!-- start: stats -->
                    <div class="order-card-header__top-stats">
                        <!-- start: discount -->
                        <span
                            v-if="order.discount && order.discount.rate > 0"
                            class="order-card-header__top-stats-discount"
                        >
                            <span class="report">
                                <span
                                    class="tool"
                                    style="width: max-content"
                                >
                                    {{ order.discount.type === 'loyalty' ? `Loyalty discount`: `Promo-code: ${order.discount.code}` }}
                                </span>
                                <span class="order-card-header__top-stats-discount-text">
                                    <i class="order-card-header__top-stats-discount-icon" />
                                    {{ new Intl.NumberFormat("en-US",{style: 'percent'}).format(order.discount.rate) }}
                                </span>
                            </span>
                        </span>
                        <!-- end: discount -->
                        <!-- start: bids -->
                        <span
                            v-if="order.counters.bids_count > 0"
                            class="order-card-header__top-stats-bids"
                        >
                            Bids:
                            <span class="order-card-header__top-stats-bids-counter">
                                {{ order.counters.bids_count }}
                            </span>
                        </span>
                        <!-- end: bids -->
                        <!-- start: total -->
                        <span class="order-card-header__top-stats-total">
                            {{ order.total | money }}
                        </span>
                        <!-- end: total -->
                        <!-- start: notification count -->
                        <div
                            class="order-card-header__top-bell-wrapper "
                            :class="getBubles === 0 ? 'grayscale' : ''"
                        >
                            <span class="order-card-header__top-bell">
                                <Bell />
                                <span
                                    v-if="getBubles > 0"
                                    class="bubble--unread bubble--unread-header"
                                >
                                    {{ getBubles }}
                                    <span class="tool">
                                        <div v-if="unreadMessagesCount > 0">{{ unreadMessagesCount }} new messages</div>
                                        <div v-if="unreadTicketMessagesCount > 0">{{ unreadTicketMessagesCount }} support replies</div>
                                        <div v-if="unreadFilesMainCount > 0">{{ unreadFilesMainCount }} new drafts</div>
                                        <div v-if="unreadFilesAddCount > 0">{{ unreadFilesAddCount }} new supporting docs</div>
                                    </span>
                                </span>
                            </span>
                        </div>
                        <!-- end: notification count -->
                        <!-- start: order-actions -->
                        <order-actions
                            :data="order"
                            @show:modal="showModal"
                        />
                        <!-- end: order-actions -->
                    </div>
                    <!-- end: stats -->
                </div>
                <div class="order-card-header__bottom order-card-header__bottom--desktop">
                    <!-- start: rating -->
                    <div
                        v-if="order.writer"
                        class="order-card-header__bottom-writer-data"
                    >
                        <avatar
                            :avatar-url="order.writer.avatar_url"
                            :name="order.writer.name"
                            :user="'writer'"
                        />
                        <div class="order-card-header__bottom-writer-info">
                            <div class="order-card-header__bottom-writer-info-name">
                                <span style="font-weight: bold">{{ order.writer.name }}</span>
                                <span style="margin-left: 5px">ID:{{ order.writer.id }} </span>
                            </div>
                            <div
                                v-if="order.testimonials && order.testimonials.length"
                                class="card_body__row--order"
                            >
                                <div
                                    v-for="(testimonial, testimonialsIndex) in order.testimonials"
                                    :key="testimonialsIndex"
                                >
                                    <div class="card_body__testimonials__right">
                                        <div class="rate-container">
                                            <star-rating
                                                :rating="getRating(testimonial)"
                                                :read-only="true"
                                                :increment="0.1"
                                                :star-size="16"
                                                :show-rating="false"
                                                :active-color="themecolor"
                                            />
                                            <div class="order-card-header__bottom-testimonials-wrapper-rate">
                                                <span class="order-card-header__bottom-testimonials-all-rate">
                                                    {{ getRating(testimonial) }}
                                                </span>
                                                <span
                                                    v-if="order.testimonials"
                                                    class="order-card-header__bottom-testimonials-review"
                                                >
                                                    ({{ order.testimonials.length }} {{ order.testimonials.length === 1 ? 'review' : 'reviews' }})
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end: rating -->
                    <div class="order-card-header__bottom-btn">
                        <div
                            v-if="allowFeedback"
                            class="report order-card-header__bottom-btn-wrapper"
                        >
                            <span class="tool">
                                Please, rate the writer's job. That helps us serve you better. Please!
                            </span>
                            <button
                                class="btn-base btn-main order-card-header__bottom-btn-star"
                                @click.prevent="feedback('rate')"
                            >
                                <Star style="width: 22px; height: 22px;" />
                                <span class="order-card-header__bottom-btn-text">Rate job</span>
                            </button>
                        </div>
                        <div
                            v-if="order.testimonials && order.testimonials.length"
                            class="report order-card-header__bottom-btn-wrapper"
                        >
                            <span class="tool">
                                Please, re-evaluate the writer's job. That helps us serve you better. Please!
                            </span>
                            <button
                                class="btn-base btn-border order-card-header__bottom-btn-edit order-card-header__bottom-btn-feedback"
                                @click.prevent="feedback('edit')"
                            >
                                <Pencil />
                                <span class="order-card-header__bottom-btn-text">Edit review</span>
                            </button>
                        </div>
                        <router-link
                            v-if="!$route.name.includes('order_')"
                            :to="{
                                name: order.status === 'INQUIRY' ? 'order_details_express' : 'order_details',
                                params: { id: order.orderid }
                            }"
                            class="order-card-header__bottom-btn-details"
                        >
                            View <span>details</span>
                        </router-link>
                        <div
                            v-if="$route.name ==='orders'"
                            class="order-card-header__bottom-chevron"
                            @click.stop="slideCard"
                        >
                            <span
                                v-if="isCardOpen"
                                class="button-action-background button-action-background-active"
                            >
                                <chevron-up-icon />
                            </span>
                            <span
                                v-else
                                class="button-action-background"
                            >
                                <chevron-down-icon />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="order-card-header__bottom order-card-header__bottom--mobile"
            :style="{marginLeft: $route.name === 'order_details' ? '0' : '40px'}"
        >
            <!-- start: rating -->
            <div
                v-if="order.writer"
                class="order-card-header__bottom-writer-data"
            >
                <avatar
                    :avatar-url="order.writer.avatar_url"
                    :name="order.writer.name"
                    :user="'writer'"
                />
                <div class="order-card-header__bottom-writer-info">
                    <div class="order-card-header__bottom-writer-info-name">
                        <span style="font-weight: bold">{{ order.writer.name }}</span>
                        <span style="margin-left: 5px">ID:{{ order.writer.id }} </span>
                    </div>
                    <div
                        v-if="order.testimonials && order.testimonials.length"
                        class="card_body__row--order"
                    >
                        <div
                            v-for="(testimonial, testimonialsIndex) in order.testimonials"
                            :key="testimonialsIndex"
                        >
                            <div class="card_body__testimonials__right">
                                <div class="rate-container">
                                    <star-rating
                                        :rating="getRating(testimonial)"
                                        :read-only="true"
                                        :increment="0.1"
                                        :star-size="16"
                                        :show-rating="false"
                                        :active-color="themecolor"
                                    />
                                    <div class="order-card-header__bottom-testimonials-wrapper-rate">
                                        <span class="order-card-header__bottom-testimonials-all-rate">
                                            {{ getRating(testimonial) }}
                                        </span>
                                        <span
                                            v-if="order.testimonials"
                                            class="order-card-header__bottom-testimonials-review"
                                        >
                                            ({{ order.testimonials.length }} {{ order.testimonials.length === 1 ? 'review' : 'reviews' }})
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end: rating -->
            <div class="order-card-header__bottom-btn">
                <div
                    v-if="allowFeedback"
                    class="report order-card-header__bottom-btn-wrapper"
                >
                    <span class="tool">
                        Please, rate the writer's job. That helps us serve you better. Please!
                    </span>
                    <button
                        class="btn-base btn-main order-card-header__bottom-btn-star"
                        @click.prevent="feedback('rate')"
                    >
                        <Star style="width: 22px; height: 22px;" />
                        <span class="order-card-header__bottom-btn-text">Rate job</span>
                    </button>
                </div>
                <div
                    v-if="order.testimonials && order.testimonials.length"
                    class="report order-card-header__bottom-btn-wrapper"
                >
                    <span class="tool">
                        Please, re-evaluate the writer's job. That helps us serve you better. Please!
                    </span>
                    <button
                        class="btn-base btn-border order-card-header__bottom-btn-edit order-card-header__bottom-btn-feedback"
                        @click.prevent="feedback('edit')"
                    >
                        <Pencil />
                        <span class="order-card-header__bottom-btn-text">Edit review</span>
                    </button>
                </div>
                <router-link
                    v-if="!$route.name.includes('order_')"
                    :to="{
                        name: order.status === 'INQUIRY' ? 'order_details_express' : 'order_details',
                        params: { id: order.orderid }
                    }"
                    class="order-card-header__bottom-btn-details"
                >
                    View <span>details</span>
                </router-link>
                <div
                    v-if="$route.name ==='orders'"
                    class="order-card-header__bottom-chevron"
                    @click.stop="slideCard"
                >
                    <span
                        v-if="isCardOpen"
                        class="button-action-background button-action-background-active"
                    >
                        <chevron-up-icon />
                    </span>
                    <span
                        v-else
                        class="button-action-background"
                    >
                        <chevron-down-icon />
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import filtersMixin from '@/mixins/filtersMixin.js'
import Avatar from '@/components/common/Avatar.vue'
import Bell from '@/components/icons/Bell'
import Star from '@/components/icons/Star.vue';
import Pencil from '@/components/icons/Pencil.vue';
import StarRating from 'vue-star-rating'
import OrderActions from '@/components/account/Cards/OrderCard/OrderActions.vue';

import ChevronUpIcon from 'mdi-vue/ChevronUp'
import ChevronDownIcon from 'mdi-vue/ChevronDown'

const Env = require('@/helpers/environment/index.js');

const EnvSettings = new Env(process.env.VUE_APP_DOMAIN);

export default {
    name: 'OrderCardHeaderNew',
    components: {
        // Clock,
        Avatar,
        Bell,
        Pencil,
        Star,
        StarRating,
        OrderActions,
        ChevronUpIcon,
        ChevronDownIcon
    },
    mixins: [filtersMixin],
    props: {
        mode: {
            type: String,
            default: 'list'
        },
        order: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            default: 1
        },
        additionalClass: {
            type: String
        },
        unreadMessagesCount: {
            type: Number,
            required: false
        },
        unreadTicketMessagesCount: {
            type: Number,
            required: false
        },
        unreadFilesMainCount: {
            type: Number,
            required: false
        },
        unreadFilesAddCount: {
            type: Number,
            required: false
        },
        isCardOpen: {
            type: Boolean
        },
        slideCard: {
            type: Function
        }
    },
    data() {
        return {
            isShowToolTip: false,
            themecolor: EnvSettings.styleSetting.color
        }
    },
    computed: {
        showDeadline() {
            return this.order.status === 'PAID' || this.order.status === 'PENDING' || this.order.status === 'UNPAID'
        },
        getStatusClassName() {
            if (this.order.status === 'PAID') return 'card_title__status--awaiting'
            if (this.order.status === 'COMPLETE' || this.order.status === 'CREDIT') return 'card_title__status--success'
            if (this.order.status === 'PENDING') return 'card_title__status--attention'
            if (this.order.status === 'REVISION') return 'card_title__status--revision'
            return 'card_title__status--error'
        },
        getBubles() {
            return this.unreadMessagesCount + this.unreadTicketMessagesCount + (this.unreadFilesMainCount || 0) + this.unreadFilesAddCount
        },
        dueInAgo() {
            return moment(this.order.due_at) < moment()
        },
        avatarDisplay() {
            if (this.$route.fullPath === `/cp/details/${this.order.orderid}`) {
                return false
            }
            return true
        },
        projectType() {
            return this.order.project_information?.type || ''
        },
        titleFragment() {
            const ellipsis = '...'
            if (this.order.project_information.title.slice(0, 60).length < this.order.project_information.title.length) {
                return this.order.project_information.title.slice(0, 60) + ellipsis
            }
            return this.order.project_information.title.slice(0, 60)
        },
        allowFeedback() {
            const array = [
                'COMPLETE',
                'REFUND',
                'CREDIT'
            ]
            if (!this.order.writer || this.order.testimonials.length) return false

            if (array.indexOf(this.order.status) === -1) return false

            return true
        },
        reviewId() {
            return this.this.order?.testimonials[0]?.testimonialid
        }
    },
    created() {
        this.order.project_information.title.length > 60 ? this.isShowToolTip = true : this.isShowToolTip = false
    },
    methods: {
        getRating(data) {
            let rating = (Number(data.delivery) + Number(data.content) + Number(data.communication)) / 3
            rating = rating.toFixed(2)
            return +rating
        },
        feedback() {
            this.$emit('show:modal', {
                orderid: this.order.orderid,
                action: 'feedback',
                modal: 'feedback-modal'
            })
        },
        showModal(payload) {
            this.$emit('show:modal', payload)
        }
    }
}
</script>

<style lang="scss">

.order-card-header{
    display: flex;
    font-size: 14px;
    @media all and (max-width: 768px) {
        width: 100%;
        flex-wrap: wrap;
    }
    &__left-side {
        width: 270px;
        padding: 0 20px 0 40px;
        @media all and (max-width: 768px) {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 0;
        }
        @media all and (max-width: 400px) {
            flex-direction: column;
        }
    }
    &__left-side-details{
        padding: 0 20px 0 10px;

    }
    &__left-side-date-wrapper{
        display: contents;
        @media all and (max-width: 400px) {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
        }
    }
    &__left-side-index{
        position: absolute;
        left: 15px;
        width: 25px;
        color: #fff;
        font-weight: 700;
        @include media768max {
            position: inherit;
            padding: 20px 23px;
            background: #1F2939;
            width: auto;
            margin-right: 20px;
            border-radius: 4px;
        }
    }
    &__left-side-date{
        margin-bottom: 12px;
        display: block;
        .date {
            &--success {
                display: flex;
                margin-top: 10px;
                color: #59c74d;
            }
            &--error {
                color: $error-color;
            }
            p {
                margin-top: 10px;
                display: flex;
            }
        }
        @include media768max {
            margin-bottom: 0;
            width: 100%;
        }
    }
    &__left-side-status{
        grid-area: status;
        text-align: center;
        justify-self: center;
        margin: 15px 0;
        width: 100%;
        font-weight: 600;
        @include media768max {
            justify-self: initial;
        }
    }
    &__right-side{
        width: 80%;
        border-left: 1px solid #EAEAEA;
        padding-left: 15px;
        @media all and (max-width: 768px) {
            width: 100%;
            padding-left: 0;
            border-left: none;
        }
    }
    &__top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media992max {
            flex-wrap: wrap;
        }
        @media all and (max-width: 768px) {
            padding: 15px 0;
            flex-wrap: nowrap;
        }
        @media all and (max-width: 576px) {
            flex-direction: column;
            align-items: flex-start;
            border-top: 1px solid #e7ecf4;
            padding: 20px 0 0 0;
            margin-top: 10px;
        }
    }
    &__top-title{
        margin-bottom: 15px;
        color: #000;
        @include media992max {
            width: 100%;
        }
        @include media768max {
            width: auto;
        }
        @include media576max {
            margin-bottom: 0;
        }
    }
    &__top-topic{
        grid-area: title;
        display: flex;
        flex-direction: column;
        @include media576max {
            flex-wrap: wrap;
        }
    }
    &__top-custom-tooltip-none {
        display: none;
    }
    &__top-subtopic{
        position: relative;
        margin-bottom: 10px;
        &--title{
            margin-bottom: 0;
            @media all and (max-width: 576px) {
                margin-bottom: 20px;
            }
        }
        @media all and (max-width: 576px) {
            margin-right: 20px;
        }
        .custom-tooltip {
            width: 17px;
            height: 17px;
            font-size: 12px;
        }
    }
    &__top-stats{
        display: flex;
        align-items: center;
        margin: 0px 0px 20px 20px;
        @include media992max {
            margin-left: 0;
        }
        @media all and (max-width: 576px) {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
    &__top-stats-discount{
        color: #219653;
        background: rgba(89, 199, 77, 0.1);
        font-size: 14px;
        font-weight: 700;
        display: flex;
        align-items: center;
        margin-right: 10px;
        padding: 6px 12px;
        border-radius: 50px;
    }
    &__top-stats-discount-text{
        display: flex;
        align-items: center;
    }
    &__top-stats-discount-icon{
        background-image: url('~@/assets/img/svg/discount-code.svg');
        background-size: cover;
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-right: 6px;
    }
    &__top-stats-total{
        font-weight: bold;
        color: #1F2939;
        font-size: 20px;
        line-height: 1;
        padding: 0 10px;
    }
    &__top-stats-bids {
        font-weight: 700;
        &-counter {
            width: 25px;
            height: 25px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: rgba($color: #1FB119, $alpha: 1.0);
            color: #fff;
            border-radius: 100px;
        }
    }
    &__top-bell-wrapper{
        justify-self: center;
        margin: 0 25px 0 15px;
    }
    &__top-bell{
        display: inline-flex;
        position: relative;
    }
    &__top-bell .bubble--unread-header{
        top: -10px;
        right: -5px;
    }
    &__top-bell .bubble--unread{
        width: 18px;
        height: 18px;
        font-size: 10px;
    }
    &__bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        //margin-right: 35px;
        @media all and (max-width: 768px) {
            width: 100%;
        }
        @media all and (max-width: 576px) {
            flex-wrap: wrap;
        }
    }
    &__bottom--desktop {
        @media all and (max-width: 1124px) {
            display: none;
        }
    }
    &__bottom--mobile {
        display: none;
        @media all and (max-width: 1124px) {
            display: flex;
            margin-left: 40px;
        }
        @media all and (max-width: 768px) {
            margin-left: 0 !important;
        }
    }
    &__bottom-writer-data{
        display: flex;
        align-items: center;
        @media all and (max-width: 576px) {
            margin-bottom: 25px;
            padding-top: 20px;
            width: 100%;
            border-top: 1px solid #e7ecf4;
        }
    }
    &__bottom-writer-data .avatar-wrapper .avatar-person .avatar-img{
        width: 48px;
        height: 48px;
    }
    &__bottom-writer-info {
        margin-left: 15px;
    }
    &__bottom-writer-info-name{
        margin-bottom: 5px;
        display: flex;
        white-space: nowrap;
    }
    &__bottom-testimonials-wrapper-rate{
        display: flex;
        align-items: center;
        @media all and (max-width: 576px) {
            margin-top: 7px;
        }
    }
    &__bottom-testimonials-all-rate{
        color: $main-color;
        font-weight: bold;
        margin-left: 5px;
        @media all and (max-width: 576px) {
            margin-left: 0;
        }
    }
    &__bottom-testimonials-review{
        margin-left: 5px;
        white-space: nowrap;
    }
    &__bottom-btn{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        @media all and (max-width: 576px) {
            width: 100%;
            margin-left: 0;
            flex-wrap: wrap;
        }
    }
    &__bottom-btn-wrapper{
        @media all and (max-width: 576px) {
            width: 100%;
        }
    }
    &__bottom-btn .btn-base {
        height: 36px;
        padding: 6px 12px;
        text-transform: inherit;
        border-radius: 4px;
        margin-right: 12px;
        @media all and (max-width: 576px) {
            width: 100%;
        }
    }
    &__bottom-btn-text{
        margin-left: 10px;
        white-space: nowrap;
        @media all and (max-width: 992px) {
            display: none;
        }
        @media all and (max-width: 576px) {
            display: block;
        }
    }
    &__bottom-btn-star svg path{
        stroke: $white;
    }
    &__bottom-btn-star:hover svg path{
        stroke: $btn-main-color;
    }
    &__bottom-btn-edit:hover svg path{
        stroke: $white;
    }
    &__bottom-btn-feedback svg path{
        stroke: $btn-border-color;
    }
    //&__bottom-btn-feedback:hover svg path{
    //    stroke: $main-color;
    //}
    &__bottom-btn-details {
        background: #1F2939;
        color: $white;
        padding: 6px 12px;
        height: 36px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        margin-right: 15px;
        font-weight: 500;
        font-size: 14px;
        justify-content: center;
        @media all and (max-width: 576px) {
            width: 100%;
            margin-top: 10px;
            margin-right: 0;
        }
        span {
            margin-left: 5px;
            @media all and (max-width: 992px) {
                display: none;
            }
            @media all and (max-width: 576px) {
                display: flex;
            }
        }
    }
    &__bottom-chevron{
        color: #000;
        display: flex;
        cursor: pointer;
        span {
            display: flex;
            font-weight: 600;
            align-items: center;
            white-space: nowrap;
        }
        @media (max-width: 576px) {
            display: none;
        }
    }
}
</style>
