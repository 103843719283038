import {
    required
} from 'vuelidate/lib/validators'

import moment from 'moment'

export const validationRules = {
    duedate: {
        required,
        minimumTimeframe(value) {
            return moment(value) > moment(this.due).add(2, 'hours')
        }
    }
}
